

const LineGradiant = ({ width = "w-full" }) => {

    return <div className={`h-0.5 ${width} bg-gradient-rainblue`}/>;



}

export default LineGradiant;